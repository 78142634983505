import {
  AlertBox,
  ContentModal,
  Loading,
  Stack,
  OrganizationProfileView,
} from '@guider-global/ui';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import {
  ICustomField,
  IMatchV2,
  IMembership,
  IProfileViewField,
  IUserProfile,
  ProfileSkill,
} from '@guider-global/shared-types';
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  getFormattedProgramFields,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import countries from 'i18n-iso-countries';
import {
  useOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { useAppContext } from 'context';
import { useAdminMatchesV2 } from 'hooks/admin/useAdminMatchesV2';

import * as Icons from 'react-icons/md';
type MDIcons = keyof typeof Icons;

export type SuggestedMatchesModalProps = {
  isLoading: () => boolean;
  matches: IMatchV2[];
  isManualMatchRequestPending: boolean;
  onCreateManualMatch: (manualMatchProfileId: string) => void;
  handleModalClose: () => void;
  isModalOpen: boolean;
  programSlug?: string;
  user: IUserProfile | undefined;
  roleName?: string;
};

export function SuggestedMatchesModal({
  handleModalClose,
  isLoading,
  matches,
  isManualMatchRequestPending,
  onCreateManualMatch,
  isModalOpen,
  programSlug,
  user,
  roleName = 'Mentor',
}: SuggestedMatchesModalProps) {
  const { getProfileImage } = useProfileImage();
  const { organizationSlug } = useAppContext();
  countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
  const { isLoadingAdminMatchesV2 } = useAdminMatchesV2({
    getSilently: false,
  });

  const { getPrograms, isLoadingSanityPrograms } =
    useSanityOrganizationPrograms({
      localeCode: 'en_GB',
      getSilently: false,
    });

  const program = getPrograms().find(
    (program) => program?.metadata?.id?.current === programSlug,
  );

  const userOrganization = user?.organizationSlug ?? 'guider';

  const { organization, isLoadingOrganization } = useOrganization({
    organizationSlug: organizationSlug ?? userOrganization,
  });

  return (
    <ContentModal
      icon={<PeopleAlt />}
      heading={`Suggested ${roleName} Matches`}
      descriptions={[`For ${user?.firstName} ${user?.lastName}`]}
      open={isModalOpen}
      isLoading={isManualMatchRequestPending}
      onClose={handleModalClose}
      primaryButtonProps={{
        label: 'Cancel',
        variant: 'outlined',
        onClick: handleModalClose,
        disabled: isManualMatchRequestPending,
      }}
    >
      {isLoading() ||
      isLoadingOrganization ||
      isLoadingSanityPrograms() ||
      isLoadingAdminMatchesV2() ? (
        <Loading isLoading={true} sx={{ height: '100%' }} />
      ) : (
        <></>
      )}

      {!isLoading() &&
      !isLoadingOrganization &&
      !isLoadingSanityPrograms() &&
      matches?.length === 0 ? (
        <AlertBox
          sx={{ pt: 3 }}
          variant="icon"
          title="No suggested matches found"
          iconVariant="info"
        />
      ) : (
        <></>
      )}
      {!isLoading() &&
      !isLoadingOrganization &&
      !isLoadingSanityPrograms() &&
      organization &&
      matches?.length !== 0 ? (
        <Stack direction="column" spacing={3} width="100%">
          {matches.map((match, index) => {
            let customFields: IProfileViewField[];

            if (program) {
              customFields = getFormattedProgramFields(
                match?.membership as IMembership,
                program,
              ) as IProfileViewField[];
            } else {
              customFields = match?.profile?.bio
                ? [
                    {
                      label: 'Bio',
                      type: 'free-text',
                      value: match?.profile?.bio,
                    },
                  ]
                : [];
            }

            const image = getProfileImage({
              profilePicture: match?.profile?.picture,
              userPicture: match?.user?.picture,
            });

            const organizationFieldsToShow = getVisibleProfileFields(
              organization?.profile_fields,
              match?.profile?.organizationFields as ICustomField[],
            );

            const fields = organizationFieldsToShow?.map((field) => {
              const name = field?.icon?.name as MDIcons;
              const Icon = name && Icons[name];
              return {
                icon: <Icon />,
                label: field?.label?.toString() ?? '',
              };
            });

            const location = `${match?.profile?.townOrCity ?? ''}${
              match?.profile?.townOrCity &&
              match?.profile?.country &&
              countries.getName(match?.profile?.country, 'en')
                ? ', '
                : ''
            }${
              match?.profile?.country
                ? countries.getName(match?.profile?.country, 'en') ?? ''
                : ''
            }`;

            const programSkills = match?.skills?.map((skill, index) => {
              const label = program?.registration?.skills?.options?.find(
                (option) =>
                  option?.id?.current === skill?.fieldSlug &&
                  option?.label &&
                  option?.label !== '',
              )?.label;

              return {
                key: `${skill.id}-${index}`,
                isMatched: skill?.matched ?? false,
                value: label ?? '',
              };
            });

            const openMatchingSkills = match.skills.map((skill, index) => {
              const allSkills = [
                ...(organization?.open_matching?.skills?.soft_skills ?? []),
                ...(organization?.open_matching?.skills?.hard_skills ?? []),
              ] as ProfileSkill[];

              const label = allSkills.find(
                (s) =>
                  s.slug === skill.fieldSlug && s?.label && s?.label !== '',
              )?.label;

              return {
                key: `${skill.id}-${index}`,
                isMatched: skill.matched ?? false,
                value: label ?? '',
              };
            });

            const skills = program ? programSkills : openMatchingSkills;

            const matchSkills =
              match.skills.length !== 0
                ? {
                    title: 'Skills matched on',
                    skills,
                  }
                : undefined;

            // TODO: Reintroduce goals when new program registration is in place

            let matchGoals;

            if (!program) {
              const goals = match?.goalCategories.map((goal, index) => {
                const label = organization?.goal_categories?.categories.find(
                  (g) => g?.goal_category_slug?.current === goal?.fieldSlug,
                )?.goal_category_name;

                return {
                  key: `${goal?.id}-${index}`,
                  isMatched: true,
                  value: label ?? '',
                };
              });

              matchGoals =
                match.goalCategories.length !== 0
                  ? {
                      title: 'Goals matched on',
                      goals,
                    }
                  : undefined;
            }

            return (
              <OrganizationProfileView
                key={match?.profile?.id}
                profileFields={fields}
                sx={{
                  mb: 3,
                  mx: 1,
                }}
                // goals={matchGoals}
                score={match.percentage}
                matchText={
                  match.percentage !== 0
                    ? `${match.percentage.toFixed(0)}% match`
                    : undefined
                }
                guideReviews={{
                  reviewsPlural: 'Reviews',
                  reviewsSingular: 'Review',
                  buttonLabel: 'Reviews',
                  noReviewsText: 'No reviews yet',
                  reviews: match.reviews,
                }}
                displayName={match?.profile?.displayName ?? ''}
                isBestMatch={index === 0}
                bestMatchLabel={'Best Match'}
                subtitles={[
                  match?.profile?.jobTitle && {
                    text: match?.profile?.jobTitle,
                    variant: 'subtitle1',
                  },
                  location && {
                    text: location,
                    variant: 'caption',
                    color: 'text.secondary',
                  },
                ]}
                avatarSrc={image && image}
                skills={matchSkills}
                goals={matchGoals}
                linkedInUrl={match?.profile?.linkedInUrl}
                viewLinkedInLabel={'View LinkedIn profile'}
                buttons={[
                  {
                    label: 'Create relationship',
                    key: `${match?.profile?.id}-btn`,
                    variant: 'contained',
                    color: 'info',
                    sx: { px: 3 },
                    onClick: () =>
                      onCreateManualMatch(match?.profile?.id ?? ''),
                    loading: isManualMatchRequestPending,
                  },
                ]}
                customFields={customFields}
              />
            );
          })}
        </Stack>
      ) : (
        <></>
      )}
    </ContentModal>
  );
}

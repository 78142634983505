import {
  IProfileProgramMembership,
  IProfileProgramOverview,
} from '@guider-global/shared-types';
import {
  AvatarBox,
  CollapsibleCard,
  Divider,
  ProgramMembershipDetailsView,
  ProgramMembershipDetailsViewProps,
  Stack,
} from '@guider-global/ui';
import { MembershipStatusStackContainer } from 'containers';
import { ProgramMembershipEmptyTableView } from 'views';

import { MatchesContainer } from '../../containers/UserView/MatchesContainer';
import {
  IRelationshipsHeaders,
  IRelationshipsRow,
  RelationshipsTableContainer,
} from '../../containers/UserView/RelationshipsTableContainer';

// Types

export interface UserData {
  userProfileId: string;
  userEmail: string;
  userDisplayName: string;
  userOrgSlug: string;
}
export type ProfileProgramData = {
  programName: string;
  programSlug: string;
  programTypeName: string;
  programTypeSlug: string;
  programVariationTypeSlug: string;
};
export type MembershipStatusProps = {
  isPublished: boolean;
  isBlocked: boolean;
  requiresApproval: boolean;
};

export type ProgramMembershipsCardProps = {
  guideMembershipContent: ProgramMembershipsGuideContent;
  traineeMembershipContent: ProgramMembershipsTraineeContent;
  profileProgramOverview: IProfileProgramOverview;
  userData: UserData;
  title: string;
  description: string;
  avatarSrc?: string;
  guideMembership?: IProfileProgramMembership;
  traineeMembership?: IProfileProgramMembership;
  guideRelationshipTableHeaders: IRelationshipsHeaders;
  traineeRelationshipTableHeaders: IRelationshipsHeaders;
};

export interface ProgramMembershipsGuideContent {
  guideMembershipDetails?: ProgramMembershipDetailsViewProps;
  guideRelationshipRows: IRelationshipsRow[];
  guideTableEmptyState: string;
  guideMembershipStatus: MembershipStatusProps;
}
export interface ProgramMembershipsTraineeContent {
  traineeMembershipDetails?: ProgramMembershipDetailsViewProps;
  traineeRelationshipRows: IRelationshipsRow[];
  traineeTableEmptyState: string;
}

// Component
export function ProgramMembershipsCard({
  guideMembershipContent,
  guideMembership,
  traineeMembership,
  traineeMembershipContent,
  profileProgramOverview,
  userData,
  title,
  description,
  avatarSrc,
  guideRelationshipTableHeaders,
  traineeRelationshipTableHeaders,
}: ProgramMembershipsCardProps) {
  //
  const { userProfileId, userDisplayName, userEmail, userOrgSlug } = userData;

  const {
    traineeTableEmptyState,
    traineeMembershipDetails,
    traineeRelationshipRows,
  } = traineeMembershipContent;

  const {
    guideRelationshipRows,
    guideTableEmptyState,
    guideMembershipDetails,
    guideMembershipStatus,
  } = guideMembershipContent;
  const {
    programSlug,
    programName,
    programTypeSlug,
    programVariationTypeSlug,
  } = profileProgramOverview;

  const manualMatchData = {
    programSlug,
    programName,
    programTypeSlug,
    programVariationTypeSlug,
    userProfileId,
    userDisplayName,
    userEmail,
  };

  return (
    <CollapsibleCard
      headerElement={
        <AvatarBox
          size="medium"
          avatarSrc={avatarSrc}
          heading={title}
          subtitles={[description]}
        />
      }
      sx={{
        borderRadius: {
          xs: 0,
          md: '8px',
          boxShadow: '0px 8px 16px 0px #00000026',
        },
      }}
    >
      {traineeMembership && traineeMembershipDetails && (
        <Stack direction={{ xs: 'column', lg: 'row' }} px={4.5} py={5} gap={3}>
          <ProgramMembershipDetailsView
            title={traineeMembershipDetails.title}
            description={traineeMembershipDetails.description}
            fields={traineeMembershipDetails.fields}
          />
          <Stack direction={'column'} flex={1} spacing={0}>
            <MatchesContainer
              membership={traineeMembership}
              manualMatchData={manualMatchData}
            />

            {traineeRelationshipRows.length > 0 ? (
              <RelationshipsTableContainer
                userOrganizationSlug={userOrgSlug}
                rows={traineeRelationshipRows}
                headers={traineeRelationshipTableHeaders}
              />
            ) : (
              <ProgramMembershipEmptyTableView
                heading={traineeTableEmptyState}
              />
            )}
          </Stack>
        </Stack>
      )}
      {traineeMembership &&
        traineeMembershipDetails &&
        guideMembership &&
        guideMembershipDetails && <Divider />}
      {guideMembership && guideMembershipDetails && (
        <Stack direction={{ xs: 'column', lg: 'row' }} px={4.5} py={5} gap={3}>
          <ProgramMembershipDetailsView
            title={guideMembershipDetails.title}
            description={guideMembershipDetails.description}
            fields={guideMembershipDetails.fields}
            children={
              <MembershipStatusStackContainer
                isPublished={guideMembershipStatus.isPublished}
                isBlocked={guideMembershipStatus.isBlocked}
                requiresApproval={guideMembershipStatus.requiresApproval}
                guideMembership={guideMembership}
              />
            }
          />

          <Stack direction={'column'} flex={1} spacing={0}>
            <MatchesContainer
              manualMatchData={manualMatchData}
              membership={guideMembership}
            />
            {guideRelationshipRows.length > 0 ? (
              <RelationshipsTableContainer
                userOrganizationSlug={userOrgSlug}
                rows={guideRelationshipRows}
                headers={guideRelationshipTableHeaders}
              />
            ) : (
              <ProgramMembershipEmptyTableView heading={guideTableEmptyState} />
            )}
          </Stack>
        </Stack>
      )}
    </CollapsibleCard>
  );
}
